import {createAction, props} from '@ngrx/store';
import {JobOfferModel} from "../models/jobOffer.model";

export const updateStatus = createAction(
  '[JobOffer] Update Status',
  props<{id: string, status: string}>()
);

export const updateStatusSuccess = createAction(
  '[JobOffer] Update Status Success',
  props<{ data: JobOfferModel }>()
);

export const updateStatusFailure = createAction(
  '[JobOffer] Update Status Failure',
  props<{ error: any }>()
);
