import {createReducer, on} from '@ngrx/store';
import * as JobOfferAction from '../actions/jobOffer.actions'
import {JobOfferModel} from "../models/jobOffer.model";

export const PushFeatureKey = 'jobOffer';

export interface State {
  current: JobOfferModel | null,
  loading: boolean,
  error: any
}

export const initialState: State = {
  current: null,
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(JobOfferAction.updateStatus, (state) => ({...state, loading: true, error: null})),
  on(JobOfferAction.updateStatusSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(JobOfferAction.updateStatusFailure, (state, {error}) => ({...state, loading: false, error})),
);


