import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";


const API_URL = environment.API_URL;
const PATH = '/cprofile/'

@Injectable({
  providedIn: 'root'
})
export class CProfileService {

  constructor(private http: HttpClient) {
  }

  loadCProfile(id: string) {
    return this.http.get<any>(API_URL + PATH + id)
  }

  getEmails(id: string) {
    return this.http.get<any>(API_URL + PATH + id + '/emails')
  }

  sendEmail(id: string, emailType: string) {
    return this.http.post<any>(API_URL + PATH + id + '/email', {type: emailType})
  }
}
