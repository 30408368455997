import {createReducer, on} from '@ngrx/store';
import * as PaperboardActions from '../actions/paperboard.actions'

export const PaperboardFeatureKey = 'paperboard';

export interface State {
  all: { [key: string]: any[]} | null,
  loading: boolean,
  error: any
}

export const initialState: State = {
  all: null,
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(PaperboardActions.loadPaperboard, (state) => ({...state, loading: true, error: null})),
  on(PaperboardActions.loadPaperboardSuccess, (state, {data}) => ({
    ...state,
    all: data,
    loading: false,
    error: null
  })),
  on(PaperboardActions.loadPaperboardFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PaperboardActions.addPage, (state) => ({...state, loading: true, error: null})),
  on(PaperboardActions.addPageSuccess, (state, {data}) => ({
    ...state,
    all: data,
    loading: false,
    error: null
  })),
  on(PaperboardActions.addPageFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PaperboardActions.updatePage, (state) => ({...state, loading: true, error: null})),
  on(PaperboardActions.updatePageSuccess, (state, {data}) => ({
    ...state,
    all: data,
    loading: false,
    error: null
  })),
  on(PaperboardActions.updatePageFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PaperboardActions.deletePage, (state) => ({...state, loading: true, error: null})),
  on(PaperboardActions.deletePageSuccess, (state, {data}) => ({
    ...state,
    all: data,
    loading: false,
    error: null
  })),
  on(PaperboardActions.deletePageFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PaperboardActions.choosePage, (state) => ({...state, loading: true, error: null})),
  on(PaperboardActions.choosePageSuccess, (state, {data}) => ({
    ...state,
    all: data,
    loading: false,
    error: null
  })),
  on(PaperboardActions.choosePageFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PaperboardActions.unChoosePage, (state) => ({...state, loading: true, error: null})),
  on(PaperboardActions.unChoosePageSuccess, (state, {data}) => ({
    ...state,
    all: data,
    loading: false,
    error: null
  })),
  on(PaperboardActions.unChoosePageFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PaperboardActions.addJobOffer, (state) => ({...state, loading: true, error: null})),
  on(PaperboardActions.addJobOfferSuccess, (state, {data}) => ({
    ...state,
    all: data,
    loading: false,
    error: null
  })),
  on(PaperboardActions.addJobOfferFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PaperboardActions.resetTM, (state) => ({...state, loading: true, error: null})),
  on(PaperboardActions.resetTMSuccess, (state, {data}) => ({
    ...state,
    all: data,
    loading: false,
    error: null
  })),
  on(PaperboardActions.resetTMFailure, (state, {error}) => ({...state, loading: false, error})),
);


