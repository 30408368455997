import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";

const API_URL = environment.API_URL;
const PATH = '/paperboard/'

@Injectable({
  providedIn: 'root'
})
export class PaperboardService {

  constructor(private http: HttpClient) {
  }

  load() {
    return this.http.get<any>(API_URL + PATH)
  }

  addJobOffer(id: string) {
    return this.http.patch<any>(API_URL + PATH + 'jobOffer', {job_offer_id: id})
  }

  updateJobOffer(id: string, page: string) {
    return this.http.patch<any>(API_URL + PATH + id, {page})
  }

  addPage(page: string) {
    return this.http.post<any>(API_URL + PATH + 'page', {page})
  }

  updatePage(oldPage: string, newPage: string) {
    return this.http.patch<any>(API_URL + PATH + 'page/' + oldPage, {page: newPage})
  }

  deletePage(page: string) {
    return this.http.delete<any>(API_URL + PATH + 'page/' + page)
  }

  choosePage(page: string) {
    return this.http.patch<any>(API_URL + PATH + 'page/' + page + '/choose', {})
  }

  unChoosePage(page: string) {
    return this.http.delete<any>(API_URL + PATH + 'page/' + page + '/unChoose', {})
  }

  resetTM() {
    return this.http.get<any>(API_URL + PATH + 'reset/')
  }
}
