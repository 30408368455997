<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu" #verticalMenu>

  <!-- LOGO -->
  <div class="navbar-brand-box">
    <a href="../../../../index.html" class="logo logo-dark">
            <span class="logo-sm">
                <img src="assets/images/logo.png" alt="" height="22">
            </span>
      <span class="logo-lg">
                <img src="assets/images/logo.png" alt="" height="22">
            </span>
    </a>

    <a href="../../../../index.html" class="logo logo-light">
            <span class="logo-lg">
                <img src="assets/images/logo.png" alt="" height="50">
<!--                <img src="favicon.png" alt="" height="100">-->
            </span>
      <span class="logo-sm">
                <img src="assets/images/logo.png" alt="" height="22">
            </span>
    </a>
  </div>

  <button type="button" class="btn btn-sm px-3 header-item vertical-menu-btn topnav-hamburger" (click)="toggleMenu()">
        <span class="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
        </span>
  </button>

  <div class="sidebar-menu-scroll" data-simplebar data-simplebar-auto-hide="false">
    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul metis-menu class="list-unstyled metismenu" id="side-menu">
        <li class="menu-title" data-key="t-menu">Menu</li>
        <li>
          <a href="#" class="has-arrow">
            <fa-icon class="icon nav-icon" [icon]="fa.faGaugeHigh"></fa-icon>
            <span class="menu-item" data-key="t-calendar">Dashboards</span>
          </a>
          <ul class="sub-menu">
            <li><a routerLink="/dashboard/activities" class="side-nav-link-ref">Activité</a></li>
            <li><a routerLink="/dashboard/paperboard" class="side-nav-link-ref">PaperBoard</a></li>
          </ul>
        </li>

        <li>
          <a href="#" class="has-arrow">
            <fa-icon class="icon nav-icon" [icon]="fa.faUsers"></fa-icon>
            <span class="menu-item" data-key="t-email">Inscrits</span>
          </a>
          <ul class="sub-menu">
            <li><a routerLink="/subscribers/table/all" class="side-nav-link-ref">Tous</a></li>
          </ul>
        </li>

        <li>
          <a href="#" class="has-arrow">
            <fa-icon class="icon nav-icon" [icon]="fa.faUsers"></fa-icon>
            <span class="menu-item" data-key="t-email">Candidats</span>
          </a>
          <ul class="sub-menu">
            <li><a routerLink="/profile/candidate/table/available" class="side-nav-link-ref">Disponible</a></li>
            <li><a routerLink="/profile/candidate/table/standby" class="side-nav-link-ref">En veille</a></li>
            <li><a routerLink="/profile/candidate/table/unavailable" class="side-nav-link-ref">Indisponible</a></li>
            <li><a routerLink="/profile/candidate/table/all" class="side-nav-link-ref">Tous</a></li>
          </ul>
        </li>

        <li>
          <a href="#" class="has-arrow">
            <fa-icon class="icon nav-icon" [icon]="fa.faBuilding"></fa-icon>
            <span class="menu-item" data-key="t-email">Entreprises</span>
          </a>
          <ul class="sub-menu">
            <li><a routerLink="/profile/enterprise/table/visible" class="side-nav-link-ref">Visible</a></li>
            <li><a routerLink="/profile/enterprise/table/invisible" class="side-nav-link-ref">Invisible</a></li>
            <li><a routerLink="/profile/enterprise/table/all" class="side-nav-link-ref">Tous</a></li>
            <li><a routerLink="/profile/prospect/table/all" class="side-nav-link-ref">Prospects</a></li>
          </ul>
        </li>

        <li>
          <a href="#" class="has-arrow">
            <fa-icon class="icon nav-icon" [icon]="fa.faShop"></fa-icon>
            <span class="menu-item" data-key="t-email">Commercial</span>
          </a>
          <ul class="sub-menu">
            <li><a routerLink="/jobOffer/table" class="side-nav-link-ref">Fiches de postes</a></li>
          </ul>
        </li>

        <li>
          <a href="#" class="has-arrow">
            <fa-icon class="icon nav-icon" [icon]="fa.faHandshakeSimple"></fa-icon>
            <span class="menu-item" data-key="t-email">Propositions</span>
          </a>
          <ul class="sub-menu">
            <li><a routerLink="/proposition/inProgress" class="side-nav-link-ref">En cours</a></li>
            <li><a routerLink="/proposition/table" class="side-nav-link-ref">Toutes</a></li>
          </ul>
        </li>

        <li>
          <a routerLink="/stats">
            <fa-icon class="icon nav-icon" [icon]="fa.faChartSimple"></fa-icon>
            <span class="menu-item" data-key="t-chat">Stats</span>
          </a>
        </li>
      </ul>
    </div>

    <!-- Sidebar -->

    <div class="p-3 px-4 sidebar-footer">
      <p class="mb-1 main-title"><script>document.write(new Date().getFullYear())</script> &copy; Fiftytalents.</p>
      <p class="mb-0">Design & Develop by Clément avec l'aide de toute l'équipe ;)</p>
    </div>
  </div>
</div>
<!-- Left Sidebar End -->
