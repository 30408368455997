import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as UserActions from '../actions/user.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from "@ngrx/store";
import {UserService} from "../apis/user.service";

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private userService: UserService, private store: Store) {
  }

  loadUser$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UserActions.loadUser),
        map((action: any) => action),
        mergeMap(() => {
          return this.userService.loadUser().pipe(
            map(data => UserActions.loadUserSuccess({data})),
            catchError(error => of(UserActions.loadUserFailure({error})))
          );
        })
      )
    }
  );

  storeUser$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UserActions.storeUser),
        map((action: any) => action),
        mergeMap(({firstName, lastName, email, userName, password}) => {
          return this.userService.create(firstName, lastName, email, userName, password).pipe(
            map(data => UserActions.storeUserSuccess({data})),
            catchError(error => of(UserActions.storeUserFailure({error})))
          );
        })
      )
    }
  );

  updateUser$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UserActions.updateUser),
        map((action: any) => action),
        mergeMap(({firstName, lastName, email, userName}) => {
          return this.userService.update(firstName, lastName, email, userName).pipe(
            map(data => UserActions.updateUserSuccess({data})),
            catchError(error => of(UserActions.updateUserFailure({error})))
          );
        })
      )
    }
  );

  updatePassword$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UserActions.updatePassword),
        map((action: any) => action),
        mergeMap(({password, confirmPassword}) => {
          return this.userService.updatePassword(password, confirmPassword).pipe(
            map(data => UserActions.updatePasswordSuccess({data})),
            catchError(error => of(UserActions.updatePasswordFailure({error})))
          );
        })
      )
    }
  );

  updateGroupUser$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UserActions.updateGroup),
        map((action: any) => action),
        mergeMap(({userId, groupId}) => {
          return this.userService.updateGroup(userId, groupId).pipe(
            map(data => UserActions.updateGroupSuccess({data})),
            catchError(error => of(UserActions.updateGroupFailure({error})))
          );
        })
      )
    }
  );

  loadAllUsers$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UserActions.loadAllUsers),
        map((action: any) => action),
        mergeMap(() => {
          return this.userService.loadAllUsers().pipe(
            map(data => UserActions.loadAllUsersSuccess({data})),
            catchError(error => of(UserActions.loadAllUsersFailure({error})))
          );
        })
      )
    }
  );

  loadUserLastConnections$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UserActions.loadUserLastConnections),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.userService.loadLastConnections(id).pipe(
            map(data => UserActions.loadUserLastConnectionsSuccess({data})),
            catchError(error => of(UserActions.loadUserLastConnectionsFailure({error})))
          );
        })
      )
    }
  );

  loadUserLastJobOffers$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UserActions.loadUserLastJobOffers),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.userService.loadLastJobOffers(id).pipe(
            map(data => UserActions.loadUserLastJobOffersSuccess({data})),
            catchError(error => of(UserActions.loadUserLastJobOffersFailure({error})))
          );
        })
      )
    }
  );

  loadUserHitsByDays$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UserActions.loadUserHitsByDay),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.userService.loadHitsByDays(id).pipe(
            map(data => UserActions.loadUserHitsByDaySuccess({data})),
            catchError(error => of(UserActions.loadUserHitsByDayFailure({error})))
          );
        })
      )
    }
  );

  loadUserGuests$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UserActions.loadUserGuests),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.userService.loadGuests(id).pipe(
            map(data => UserActions.loadUserGuestsSuccess({data})),
            catchError(error => of(UserActions.loadUserGuestsFailure({error})))
          );
        })
      )
    }
  );

  loadUserCalendly$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UserActions.loadUserCalendly),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.userService.loadCalendly(id).pipe(
            map(data => UserActions.loadUserCalendlySuccess({data})),
            catchError(error => of(UserActions.loadUserCalendlyFailure({error})))
          );
        })
      )
    }
  );

  loadInactivesCandidats$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UserActions.loadInactivesCandidats),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.userService.loadInactivesCandidats(id).pipe(
            map(data => UserActions.loadInactivesCandidatsSuccess({data})),
            catchError(error => of(UserActions.loadInactivesCandidatsFailure({error})))
          );
        })
      )
    }
  );

  loadUserStats$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(UserActions.loadUserStats),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.userService.loadStats(id).pipe(
            map(data => UserActions.loadUserStatsSuccess({data})),
            catchError(error => of(UserActions.loadUserStatsFailure({error})))
          );
        })
      )
    }
  );
}
