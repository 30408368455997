import {createAction, props} from '@ngrx/store';

export const loadCProfile = createAction(
  '[CProfile] Load CProfile',
  props<{ id: string }>()
);

export const loadCProfileSuccess = createAction(
  '[CProfile] Load CProfile Success',
  props<{ data: any }>()
);

export const loadCProfileFailure = createAction(
  '[CProfile] Load CProfile Failure',
  props<{ error: any }>()
);

export const getEmails = createAction(
  '[CProfile] Get Emails',
  props<{ id: string }>()
);

export const getEmailsSuccess = createAction(
  '[CProfile] Get Emails Success',
  props<{ data: any }>()
);

export const getEmailsFailure = createAction(
  '[CProfile] Get Emails Failure',
  props<{ error: any }>()
);

export const sendEmail = createAction(
  '[CProfile] Send Email',
  props<{ id: string, emailType: string }>()
);

export const sendEmailSuccess = createAction(
  '[CProfile] Send Email Success',
  props<{ data: any }>()
);

export const sendEmailFailure = createAction(
  '[CProfile] Send Email Failure',
  props<{ error: any }>()
);
