import {createAction, props} from '@ngrx/store';

export const loadPaperboard = createAction(
  '[Paperboard] Load Paperboard');

export const loadPaperboardSuccess = createAction(
  '[Paperboard] Load Paperboard Success',
  props<{ data: any }>()
);

export const loadPaperboardFailure = createAction(
  '[Paperboard] Load Paperboard Failure',
  props<{ error: any }>()
);

export const addJobOffer = createAction(
  '[Paperboard] Add JobOffer',
  props<{ id: string }>()
);

export const addJobOfferSuccess = createAction(
  '[Paperboard] Add JobOffer Success',
  props<{ data: any }>()
);

export const addJobOfferFailure = createAction(
  '[Paperboard] Add JobOffer Failure',
  props<{ error: any }>()
);

export const updateJobOffer = createAction(
  '[Paperboard] Update JobOffer',
  props<{ id: string, page: string }>()
);

export const updateJobOfferSuccess = createAction(
  '[Paperboard] Update JobOffer Success',
  props<{ data: any }>()
);

export const updateJobOfferFailure = createAction(
  '[Paperboard] Update JobOffer Failure',
  props<{ error: any }>()
);

export const addPage = createAction(
  '[Paperboard] Add page',
  props<{ page: string }>()
);

export const addPageSuccess = createAction(
  '[Paperboard] Add page Success',
  props<{ data: any }>()
);

export const addPageFailure = createAction(
  '[Paperboard] Add page Failure',
  props<{ error: any }>()
);

export const updatePage = createAction(
  '[Paperboard] Update page',
  props<{ oldPage: string, newPage: string }>()
);

export const updatePageSuccess = createAction(
  '[Paperboard] Update page Success',
  props<{ data: any }>()
);

export const updatePageFailure = createAction(
  '[Paperboard] Update page Failure',
  props<{ error: any }>()
);

export const deletePage = createAction(
  '[Paperboard] Delete page',
  props<{ page: string }>()
);

export const deletePageSuccess = createAction(
  '[Paperboard] Delete page Success',
  props<{ data: any }>()
);

export const deletePageFailure = createAction(
  '[Paperboard] Delete page Failure',
  props<{ error: any }>()
);

export const choosePage = createAction(
  '[Paperboard] choose page',
  props<{ page: string }>()
);

export const choosePageSuccess = createAction(
  '[Paperboard] choose page Success',
  props<{ data: any }>()
);

export const choosePageFailure = createAction(
  '[Paperboard] choose page Failure',
  props<{ error: any }>()
);

export const unChoosePage = createAction(
  '[Paperboard] Delete choose page',
  props<{ page: string }>()
);

export const unChoosePageSuccess = createAction(
  '[Paperboard] Delete choose page Success',
  props<{ data: any }>()
);

export const unChoosePageFailure = createAction(
  '[Paperboard] Delete choose page Failure',
  props<{ error: any }>()
);

export const resetTM = createAction(
  '[Paperboard] reset TM'
);

export const resetTMSuccess = createAction(
  '[Paperboard] reset TM Success',
  props<{ data: any }>()
);

export const resetTMFailure = createAction(
  '[Paperboard] reset TM Failure',
  props<{ error: any }>()
);
