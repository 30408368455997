import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as JobOfferActions from '../actions/jobOffer.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {JobOfferService} from "../apis/jobOffer.service";

@Injectable()
export class JobOfferEffects {
  constructor(private actions$: Actions, private jobOfferService: JobOfferService, private store: Store,
              private toast: ToastrService) {
  }

  update$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(JobOfferActions.updateStatus),
        map((action: any) => action),
        mergeMap(({id, status}) => {
          return this.jobOfferService.updateStatus(id, status).pipe(
            map(data => JobOfferActions.updateStatusSuccess({data})),
            catchError(error => of(JobOfferActions.updateStatusFailure({error})))
          );
        })
      )
    }
  );
}
