import {createReducer, on} from '@ngrx/store';
import * as UserActions from '../actions/user.actions'
import {UserModel} from "../models/user.model";
import {PropositionModel} from "../models/proposition.model";
import {CProfileModel} from "../models/cProfile.model";

export const UserFeatureKey = 'user';

export interface State {
  user: UserModel | null,
  last_connections: any[],
  last_job_offers: any[],
  hits_by_days: {eProfiles: any, cProfiles: any, hits: any},
  guests: any[],
  calendly: any[],
  inactives: CProfileModel[],
  stats: {global: any, propositions_pending: any, enterprises_visible: any, enterprises_created: any, propositions: PropositionModel[], nb_enterprises_with_propositions: any, nb_proposition_created: any, candidates_created: any, candidates_reactivated: any, nb_candidate_with_propositions: any}
  all: UserModel[],
  loading: boolean,
  error: any
}

export const initialState: State = {
  user: null,
  last_connections: [],
  last_job_offers: [],
  hits_by_days: {eProfiles: [], cProfiles: [], hits: []},
  guests: [],
  calendly: [],
  inactives: [],
  stats: {global: [], propositions_pending: [], enterprises_visible: 0, enterprises_created: 0, propositions: [], nb_enterprises_with_propositions: [], nb_proposition_created: [], candidates_created: 0, candidates_reactivated: 0, nb_candidate_with_propositions: []},
  all: [],
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(UserActions.loadUser, (state) => ({...state, loading: true, error: null})),
  on(UserActions.loadUserSuccess, (state, {data}) => ({
    ...state,
    user: data,
    loading: false,
    error: null
  })),
  on(UserActions.loadUserFailure, (state, {error}) => ({...state, loading: false, error})),
  on(UserActions.loadAllUsers, (state) => ({...state, loading: true, error: null})),
  on(UserActions.loadAllUsersSuccess, (state, {data}) => ({
    ...state,
    all: data,
    loading: false,
    error: null
  })),
  on(UserActions.loadAllUsersFailure, (state, {error}) => ({...state, loading: false, error})),
  on(UserActions.loadUserLastConnectionsSuccess, (state, {data}) => ({
    ...state,
    last_connections: data,
    loading: false,
    error: null
  })),
  on(UserActions.loadUserLastJobOffersSuccess, (state, {data}) => ({
    ...state,
    last_job_offers: data,
    loading: false,
    error: null
  })),
  on(UserActions.loadUserHitsByDay, (state) => ({...state, hits_by_days: {eProfiles: [], cProfiles: [], hits: []}, loading: true, error: null})),
  on(UserActions.loadUserHitsByDaySuccess, (state, {data}) => ({
    ...state,
    hits_by_days: data,
    loading: false,
    error: null
  })),
  on(UserActions.loadUserGuests, (state) => ({...state, guests: [], loading: true, error: null})),
  on(UserActions.loadUserGuestsSuccess, (state, {data}) => ({
    ...state,
    guests: data,
    loading: false,
    error: null
  })),
  on(UserActions.loadUserCalendly, (state) => ({...state, calendly: [], loading: true, error: null})),
  on(UserActions.loadUserCalendlySuccess, (state, {data}) => ({
    ...state,
    calendly: data,
    loading: false,
    error: null
  })),
  on(UserActions.loadUserStats, (state) => ({...state, stats: {global: [], propositions_pending: [], enterprises_visible: 0, enterprises_created: 0, propositions: state.stats.propositions, nb_enterprises_with_propositions: [], nb_proposition_created: [], candidates_created: 0, candidates_reactivated: 0, nb_candidate_with_propositions: []}, loading: true, error: null})),
  on(UserActions.loadUserStatsSuccess, (state, {data}) => ({
    ...state,
    stats: data,
    loading: false,
    error: null
  })),
  on(UserActions.loadInactivesCandidats, (state) => ({...state, calendly: [], loading: true, error: null})),
  on(UserActions.loadInactivesCandidatsSuccess, (state, {data}) => ({
    ...state,
    inactives: data,
    loading: false,
    error: null
  })),
  on(UserActions.loadInactivesCandidatsFailure, (state, {error}) => ({...state, loading: false, error})),
  on(UserActions.storeUserFailure, (state, {error}) => ({...state, loading: false, error})),
  on(UserActions.updateUserFailure, (state, {error}) => ({...state, loading: false, error})),
  on(UserActions.updatePasswordFailure, (state, {error}) => ({...state, loading: false, error})),
);


