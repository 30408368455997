import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], field : string, value : any, exclude: boolean = false): any[] {
    if (!items) return [];
    if (typeof value !== 'boolean' && value !== null && ((!value && value !== 0) || value.length == 0)) return items;
    return items.filter(it => {
      if (!exclude) {
        return it[field] == value
      } else {
        return it[field] != value
      }
    });
  }
}
