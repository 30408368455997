import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as PaperboardActions from '../actions/paperboard.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of, tap} from 'rxjs';
import {PaperboardService} from "../apis/paperboard.service";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class PaperboardEffects {
  constructor(private actions$: Actions, private paperboardService: PaperboardService, private toast: ToastrService) {
  }

  loadPaperboard$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PaperboardActions.loadPaperboard),
        map((action: any) => action),
        mergeMap(() => {
          return this.paperboardService.load().pipe(
            map(data => PaperboardActions.loadPaperboardSuccess({data})),
            catchError(error => of(PaperboardActions.loadPaperboardFailure({error})))
          );
        })
      )
    }
  );

  addJobOffer$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PaperboardActions.addJobOffer),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.paperboardService.addJobOffer(id).pipe(
            map(data => PaperboardActions.addJobOfferSuccess({data})),
            catchError(error => of(PaperboardActions.addJobOfferFailure({error})))
          );
        })
      )
    }
  );

  addJobOfferSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaperboardActions.addJobOfferSuccess),
        tap(() => {
          this.toast.success('Paperboard modifié')
        }),
      );
    },
    { dispatch: false }
  );

  updateJobOffer$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PaperboardActions.updateJobOffer),
        map((action: any) => action),
        mergeMap(({id, page}) => {
          return this.paperboardService.updateJobOffer(id, page).pipe(
            map(data => PaperboardActions.updateJobOfferSuccess({data})),
            catchError(error => of(PaperboardActions.updateJobOfferFailure({error})))
          );
        })
      )
    }
  );

  updateJobOfferSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaperboardActions.updateJobOfferSuccess),
        tap(() => {
          this.toast.success('Paperboard modifié')
        }),
      );
    },
    { dispatch: false }
  );

  addPage$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PaperboardActions.addPage),
        map((action: any) => action),
        mergeMap(({page}) => {
          return this.paperboardService.addPage(page).pipe(
            map(data => PaperboardActions.addPageSuccess({data})),
            catchError(error => of(PaperboardActions.addPageFailure({error})))
          );
        })
      )
    }
  );

  addPageSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaperboardActions.addPageSuccess),
        tap(() => {
          this.toast.success('Nouvelle Page')
        }),
      );
    },
    { dispatch: false }
  );

  updatePage$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PaperboardActions.updatePage),
        map((action: any) => action),
        mergeMap(({oldPage, newPage}) => {
          return this.paperboardService.updatePage(oldPage, newPage).pipe(
            map(data => PaperboardActions.updatePageSuccess({data})),
            catchError(error => of(PaperboardActions.updatePageFailure({error})))
          );
        })
      )
    }
  );

  updatePageSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaperboardActions.updatePageSuccess),
        tap(() => {
          this.toast.success('Page modifiée')
        }),
      );
    },
    { dispatch: false }
  );

  deletePage$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PaperboardActions.deletePage),
        map((action: any) => action),
        mergeMap(({page}) => {
          return this.paperboardService.deletePage(page).pipe(
            map(data => PaperboardActions.deletePageSuccess({data})),
            catchError(error => of(PaperboardActions.deletePageFailure({error})))
          );
        })
      )
    }
  );

  deletePageSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaperboardActions.deletePageSuccess),
        tap(() => {
          this.toast.success('Page déchirée !')
        }),
      );
    },
    { dispatch: false }
  );

  choosePage$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PaperboardActions.choosePage),
        map((action: any) => action),
        mergeMap(({page}) => {
          return this.paperboardService.choosePage(page).pipe(
            map(data => PaperboardActions.choosePageSuccess({data})),
            catchError(error => of(PaperboardActions.choosePageFailure({error})))
          );
        })
      )
    }
  );

  choosePageSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaperboardActions.choosePageSuccess),
        tap(() => {
          this.toast.success('C\'est noté')
        }),
      );
    },
    { dispatch: false }
  );

  unChoosePage$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PaperboardActions.unChoosePage),
        map((action: any) => action),
        mergeMap(({page}) => {
          return this.paperboardService.unChoosePage(page).pipe(
            map(data => PaperboardActions.unChoosePageSuccess({data})),
            catchError(error => of(PaperboardActions.unChoosePageFailure({error})))
          );
        })
      )
    }
  );

  unChoosePageSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaperboardActions.unChoosePageSuccess),
        tap(() => {
          this.toast.success('C\'est noté')
        }),
      );
    },
    { dispatch: false }
  );

  resetTM$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PaperboardActions.resetTM),
        map((action: any) => action),
        mergeMap(({page}) => {
          return this.paperboardService.resetTM().pipe(
            map(data => PaperboardActions.resetTMSuccess({data})),
            catchError(error => of(PaperboardActions.resetTMFailure({error})))
          );
        })
      )
    }
  );

  resetTMSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaperboardActions.resetTMSuccess),
        tap(() => {
          this.toast.success('C\'est fait')
        }),
      );
    },
    { dispatch: false }
  );

}
